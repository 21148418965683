import { gsap } from "gsap";

const slider_images = document.querySelectorAll('.slider_images')
const mobile_view = window.matchMedia('(max-width:500px)')
const larger_desktop_view = window.matchMedia('(min-width:1919px)')

const heading = document.querySelectorAll('.slider_images h1')
const sub_heading = document.querySelectorAll('.slider_images h5')
const content = document.querySelectorAll('.slider_images p')
const arrow = document.querySelectorAll('.slider_content img')
const overlay = document.querySelectorAll('.overlay')
const details = document.querySelectorAll('.details')
const gradient_overlay = document.querySelectorAll('gradient_overlay')
const banner_arrows = document.querySelectorAll('.banner_arrows')
const caption = document.querySelector('.div1 ')
const logo = document.querySelector('.logo')
const socials = document.querySelector('.socials')
const social = document.querySelector('.social_container')
const social_div = document.querySelector('.social_div')
const socials_images = social_div.querySelectorAll('img')


const contact_text = document.querySelector('.contact_text')
const contact_icon = document.querySelector('.contact_icon')
const contact_info = document.querySelector('.contact_info')
const contact_content = document.querySelector('.contact_info .container')

var toggle_bool = false
var contact_toggle = false
var socials_toggle = true

//Sections click animations codes here...

slider_images.forEach((items, index) => {

    const this_heading = items.querySelector('h1')
    const this_sub_heading = items.querySelector('h5')
    const this_content = items.querySelector('p')
    const this_arrow = items.querySelector('.slider_content img')
    const this_overlay = items.querySelector('.overlay')
    const this_gradient_overlay = items.querySelector('.gradient_overlay')
    const this_details = items.querySelector('.details')

    console.log(details)

    //Panel Animation codes here...
    items.onclick = () => {

        if (mobile_view.matches) {

            gsap.to(slider_images, {
                height: '7dvh',
                duration: .8
            })

            gsap.to(arrow, {
                rotate: 0
            })


            gsap.to([sub_heading, content], {
                opacity: 0,
                display: 'none'

            })

            gsap.to(heading, {
                width: '90%',
                fontSize: '1.1rem'
            })

            gsap.to(items, {
                height: '50dvh',
                duration: .8
            })

            gsap.to([this_sub_heading, this_content], {
                opacity: 1,
                display: 'block'
            })

            gsap.to(this_heading, {
                width: '10%',
                fontSize: '2.5rem'
            })

            gsap.to(this_arrow, {
                rotate: 180
            })

            gsap.to([overlay, this_overlay], {
                opacity: 1
            })

            gsap.to(this_overlay, {
                opacity: 0
            })

            gsap.to(contact_info,{
                width: '6.8%',
                height:'20px',
                top:'50%',
                transform: 'translateY(-50%)',
                duration:.6,
                ease:'power1.inOut',
                delay:.2,
            })
    
            gsap.to([logo,socials,contact_text],{
                opacity:1
            })
    
            gsap.to(contact_content,{
                
                opacity:0,
                
                 ease:'power1.inOut',
                onComplete:()=>{
                    gsap.to(contact_content,{
                        width:0,
                        
                    })
                }
            })
    
            gsap.to(contact_icon,{
                rotate:0,
                marginLeft:'0px',
                marginTop:'0px',
                delay:.5
            })

            gsap.to(social_div,{
                width:'35px',
                height:'35px',
                top:'50%',
                right:'1.5%',
                marginTop:0,
                delay:.3
            })
    
            gsap.to(socials_images,{
                opacity:0,
            })


            //Caption change codes here...
            if (index === 0) {
                const template = `
                    <p>
                        Architects
                        <br>
                        & Creators
                    </p>
                `

                caption.innerHTML = template

            } else if (index === 1) {

                const template = `
                 <p>contractors</p>
                 <p>materials</p>
                 <p>developers</p>
            `

                caption.innerHTML = template

            } else if (index === 2) {

                const template = `
                <p>Community</p>
                <p>Support</p>
            `

                caption.innerHTML = template
            }




        } else if(larger_desktop_view.matches){
            
            if (toggle_bool = !toggle_bool) {
                gsap.to(slider_images, {
                    width: '5%',
                    duration: .8,
                })

               
                gsap.to(items, {
                    width: '90%',
                    duration: .8,
                    onComplete:()=>{
                        gsap.to(this_details, {
                            display: 'block',
                            opacity: 1,
                            duration:1
                            
                        })
                    }
                })

                gsap.to(details, {
                    display: 'none',
                    opacity: 0,
                    
                })


                gsap.to([this_sub_heading, this_content], {
                    opacity: 1,
                    display: 'block'
                })

                gsap.to(this_overlay, {
                    opacity: 0
                })

                

                gsap.to(this_arrow, {
                    rotate: 180
                })

                gsap.to(contact_info,{
                    width: '40px',
                    duration:.8,
                    ease:'power1.inOut'
                })
        
                gsap.to([logo,socials,contact_text],{
                    opacity:1
                })
        
                gsap.to(contact_content,{
                    opacity:0,
                    onComplete:()=>{
                        gsap.to(contact_content,{
                            display:'none',
                            delay:'-.5',
                            
                        })
                    }
                })
        
                gsap.to(contact_icon,{
                    rotate:0,
                })

                
    

            } else {
                

                gsap.to([sub_heading, content], {
                    opacity: 0,
                    display: 'none'

                })

                gsap.to(arrow, {
                    rotate: 0
                })

                gsap.to(heading, {
                    width: '100%',
                    fontSize: '2rem'
                })

                gsap.to(overlay, {
                    opacity: 1
                })

                gsap.to(arrow, {
                    rotate: 0
                })

                gsap.to(details, {
                    display: 'none',
                    opacity: 0,
                    onComplete: () => {
                        gsap.to(slider_images, {
                            width: '33.33%',
                        })
                    }
                })
            }



            


            gsap.to(banner_arrows, {
                width: '5%',
            })

            

            gsap.to(gradient_overlay, {
                opacity: .3
            })


            // gsap.to([sub_heading, content], {
            //     opacity: 0,
            //     display: 'none'

            // })


            gsap.to(heading, {
                width: '90%',
                fontSize: '1.1rem'
            })




            gsap.to(this_heading, {
                width: 'fit-content',
                fontSize: '2rem',
                paddingRight: 'max(5px,3%)'
            })

            
            

            

            gsap.to(this_gradient_overlay, {
                opacity: 1
            })
            
        }        
        else {

            if (toggle_bool = !toggle_bool) {
                gsap.to(slider_images, {
                    width: '5%',
                    duration: .8,
                })

               
                gsap.to(items, {
                    width: '90%',
                    duration: .8,
                    onComplete:()=>{
                        gsap.to(this_details, {
                            display: 'block',
                            opacity: 1,
                            duration:1
                            
                        })
                    }
                })

                gsap.to(details, {
                    display: 'none',
                    opacity: 0,
                    
                })


                gsap.to([this_sub_heading, this_content], {
                    opacity: 1,
                    display: 'block'
                })

                gsap.to(this_overlay, {
                    opacity: 0
                })

                

                gsap.to(this_arrow, {
                    rotate: 180
                })

                gsap.to(contact_info,{
                    width: '2.9%',
                    duration:.8,
                    ease:'power1.inOut'
                })
        
                gsap.to([logo,socials,contact_text],{
                    opacity:1
                })
        
                gsap.to(contact_content,{
                    opacity:0,
                    onComplete:()=>{
                        gsap.to(contact_content,{
                            display:'none',
                            delay:'-.5',
                            
                        })
                    }
                })
        
                gsap.to(contact_icon,{
                    rotate:0,
                })

                
    

            } else {
                

                gsap.to([sub_heading, content], {
                    opacity: 0,
                    display: 'none'

                })

                gsap.to(arrow, {
                    rotate: 0
                })

                gsap.to(heading, {
                    width: '100%',
                    fontSize: '2rem'
                })

                gsap.to(overlay, {
                    opacity: 1
                })

                gsap.to(arrow, {
                    rotate: 0
                })

                gsap.to(details, {
                    display: 'none',
                    opacity: 0,
                    onComplete: () => {
                        gsap.to(slider_images, {
                            width: '33.33%',
                        })
                    }
                })
            }



            


            gsap.to(banner_arrows, {
                width: '5%',
            })

            

            gsap.to(gradient_overlay, {
                opacity: .3
            })


            // gsap.to([sub_heading, content], {
            //     opacity: 0,
            //     display: 'none'

            // })


            gsap.to(heading, {
                width: '90%',
                fontSize: '1.1rem'
            })




            gsap.to(this_heading, {
                width: 'fit-content',
                fontSize: '2rem',
                paddingRight: 'max(5px,3%)'
            })

            
            

            

            gsap.to(this_gradient_overlay, {
                opacity: 1
            })
        }

    }


})

//Contact button codes begins here...
contact_icon.onclick = ()=>{

    if(mobile_view.matches){
        if(contact_toggle=!contact_toggle){

            gsap.to(contact_info,{
                width: '100%',
                height:'200px',
                duration:.6,
                ease:'power1.inOut',
                top:'15%',
                transform:'translate(0)'
                
            })
    
            gsap.to([logo,socials,contact_text],{
                opacity:0
            })
    
            gsap.to(contact_content,{
                display:'flex',
                width:'100%',
                delay:'.3',
                onComplete:()=>{
                    gsap.to(contact_content,{
                        opacity:1,
                       
                        
                    })
                }
            })
    
            gsap.to(contact_icon,{
                rotate:180,
                marginLeft:'5px',
                 marginTop:'10px'
            })

            gsap.to(social_div,{
                width:'35px',
                height:'35px',
                top:'50%',
                right:'1.5%',
                marginTop:0,
                delay:.3
            })
    
            gsap.to(socials_images,{
                opacity:0,
            })
    
        }else{
    
            gsap.to(contact_info,{
                width: '6.8%',
                height:'20px',
                top:'50%',
                transform: 'translateY(-50%)',
                duration:.6,
                ease:'power1.inOut',
                delay:.5,
            })
    
            gsap.to([logo,socials,contact_text],{
                opacity:1
            })
    
            gsap.to(contact_content,{
                
                opacity:0,
                
                 ease:'power1.inOut',
                onComplete:()=>{
                    gsap.to(contact_content,{
                        width:0,
                        
                    })
                }
            })
    
            gsap.to(contact_icon,{
                rotate:0,
                marginLeft:'0px',
                marginTop:'0px',
                delay:.5
            })
    
        }
    }else if(larger_desktop_view.matches){
            if(contact_toggle=!contact_toggle){
        
                gsap.to(contact_info,{
                    width: '100%',
                    duration:.8,
                    ease:'power1.inOut'
                })
        
                gsap.to([logo,socials,contact_text],{
                    opacity:0
                })
        
                gsap.to(contact_content,{
                    display:'flex',
                    delay:'.5',
                    onComplete:()=>{
                        gsap.to(contact_content,{
                            opacity:1,
                            
                        })
                    }
                })
        
                gsap.to(contact_icon,{
                    rotate:180,
                })
        
            }else{
        
                gsap.to(contact_info,{
                    width: '40px',
                    duration:.8,
                    ease:'power1.inOut'
                })
        
                gsap.to([logo,socials,contact_text],{
                    opacity:1
                })
        
                gsap.to(contact_content,{
                    opacity:0,
                    onComplete:()=>{
                        gsap.to(contact_content,{
                            display:'none',
                            delay:'-.5',
                            
                        })
                    }
                })
        
                gsap.to(contact_icon,{
                    rotate:0,
                })
        
            }
        
        
      
    }else{
        if(contact_toggle=!contact_toggle){

            gsap.to(contact_info,{
                width: '100%',
                duration:.8,
                ease:'power1.inOut'
            })
    
            gsap.to([logo,socials,contact_text],{
                opacity:0
            })
    
            gsap.to(contact_content,{
                display:'flex',
                delay:'.5',
                onComplete:()=>{
                    gsap.to(contact_content,{
                        opacity:1,
                        
                    })
                }
            })
    
            gsap.to(contact_icon,{
                rotate:180,
            })
    
        }else{
    
            gsap.to(contact_info,{
                width: '2.8%',
                duration:.8,
                ease:'power1.inOut'
            })
    
            gsap.to([logo,socials,contact_text],{
                opacity:1
            })
    
            gsap.to(contact_content,{
                opacity:0,
                onComplete:()=>{
                    gsap.to(contact_content,{
                        display:'none',
                        delay:'-.5',
                        
                    })
                }
            })
    
            gsap.to(contact_icon,{
                rotate:0,
            })
    
        }
    }

   

    
}

//Socials button click begins here...
social.onclick = ()=>{
    socialAnimateIn()
}

const socialAnimateIn= ()=>{
  

    if(socials_toggle=!socials_toggle){
        gsap.to(social_div,{
            width:'45px',
            height:'180px',
            right:'.5%',
            top:'140%',
        })

        gsap.to(socials_images,{
            opacity:1,
            delay:.3
        })
    }else{
        gsap.to(social_div,{
            width:'35px',
            height:'35px',
            top:'50%',
            right:'1.5%',
            marginTop:0,
            delay:.3
        })

        gsap.to(socials_images,{
            opacity:0,
        })
    }

    

    
}

socials_toggle=!socials_toggle

if(larger_desktop_view.matches){
    gsap.set(contact_info,{
        width: '40px',
        duration:.8,
        ease:'power1.inOut'
    })
}
